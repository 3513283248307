import { ReactElement } from "react"
import {
    NxButton,
    NxButtonLink,
    NxButtonVariant,
    NxLoader,
    NxTable,
    NxTableColumn
} from '@nextbank/ui-components';
import useAxios from "axios-hooks";
import { useParams } from "react-router";
import { HttpError } from "tools/HttpTypes";
import commandAccessService from 'command/commandAccessService';
import useDictionaryEnums from "hooks/useDictionaryEnums";
import DateFormatter from "tools/DateFormatter";

const columns: NxTableColumn<any>[] = [
    {
        title: "No.",
        field: "index",
    },
    {
        title: "Event type",
        field: "type",
    },
    {
        title: "Event date",
        field: "dateCreated",
    },
    {
        title: "Event status",
        field: "status",
    },
    {
        title: "Event status update",
        field: "dateUpdated",
    },
];

const NegativeInformation = (): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const [{ data: negativeEvent, loading: negativeEventLoading }, fetchNegativeEvent] = useAxios<any, HttpError>(
        `/cocree/customers/${customerId}/negative-events`
    );
    const [{ data: deletedNegativeEvent, loading: isDeleteNegativeEventLoading }, deleteNegativeEvent] = useAxios<any>(
        "/command/DeleteNegativeEvent",
        {
            manual: true,
        }
    );
    const { dictionaryEnumsData, loading: dictionaryEnumsLoading }: any = useDictionaryEnums();
    const hasDeleteAccess = commandAccessService.canExecute("DeleteNegativeEvent");
    const dateFormatter = new DateFormatter();
    
    negativeEvent?.forEach((event: any, i: number) => {
        // Add index on table
        event.index = i + 1;
        // Convert Event type string ex. "LIQUIDATION" to "Liquidation" from dictionary enums data
        for (const [key, value] of Object.entries(dictionaryEnumsData?.NegativeEventType || {})) {
            if (event.type === value) {
                event.type = key;
                break;
            }
        }
        // Convert Event status string
        for (const [key, value] of Object.entries(dictionaryEnumsData?.NegativeEventStatus || {})) {
            if (event.status === value) {
                event.status = key;
                break;
            }
        }
        // Format dates
        if (event.dateCreated && event.dateCreated.length < 12) {
            event.dateCreated = dateFormatter.prettyDate(event.dateCreated);
        }
        if (event.dateUpdated && event.dateUpdated.length < 12) {
            event.dateUpdated = dateFormatter.prettyDate(event.dateUpdated);
        }
    });

    const OpenNegativeEvent = ({ data }: { data: any }): ReactElement => {
        return <NxButtonLink variant={NxButtonVariant.CONTAINED} to={`/customer/${customerId}/cocree/negative-information/${data.id}`}>Open</NxButtonLink>;
    }

    const DeleteNegativeEvent = ({ data }: { data: any }): ReactElement => {
        return (data.reported || !hasDeleteAccess ? <></> : <NxButton variant={NxButtonVariant.DELETE} onClick={async () => {
            try {
                await deleteNegativeEvent({
                    method: 'POST',
                    data: {
                        id: data.id
                    }
                })

                await fetchNegativeEvent()
            } catch (error) {
                console.log(error)
            }
        }}>Delete</NxButton>)
    }

    return (
        <>
            {!negativeEventLoading && !isDeleteNegativeEventLoading ? <div>
                Negative information

                <NxTable columns={columns}
                    rowActions={[OpenNegativeEvent, DeleteNegativeEvent]}
                    data={negativeEvent} />
            </div> : <NxLoader />}
        </>
    )
}

export default NegativeInformation;