import commandAccessService from 'command/commandAccessService';
import { ReactElement, useState, useEffect } from "react";
import * as Yup from "yup";
import {
    NxFormik,
    NxLoader,
    NxButton,
    NxFormikSelect,
    NxPopup,
    NxStack,
    NxRow,
    NxRowPosition,
    NxButtonVariant,
    NxFormikDatePicker,
    NxFormikInput
} from "@nextbank/ui-components";
import NxForm from "form/NxForm";
import useAxios from "axios-hooks";
import { HttpError } from "tools/HttpTypes";
import { useHistory } from "react-router";
import { useParams } from 'react-router';
import useDictionaryEnums from 'hooks/useDictionaryEnums';
import tokenStore from 'authentication/tokenStore';
import BranchService from 'management/BranchService';


const branchService = new BranchService();

interface NegativeInformationData {
    customerId?: number;
    details?: string;
    type?: string;
    status?: string;
    dateCreated?: string;
    dateUpdated?: string;
}

const NegativeInformationForm = (): ReactElement => {
    const { customerId } = useParams<{ customerId: string }>();
    const { negativeInformationId } = useParams<{ negativeInformationId: string }>();
    const [{ data: negativeEventList, loading: negativeEventListLoading }] = useAxios<any, HttpError>(
        `/cocree/customers/${customerId}/negative-events/`
    );
    const [{ data: negativeEvent, loading: negativeEventLoading }, fetchNegativeEvent] = useAxios<any, HttpError>(
        `/cocree/customers/${customerId}/negative-events/${negativeInformationId ?? ''}`
    );
    const [{ data, loading }, createNegativeEvent] = useAxios<NegativeInformationData>(
        negativeEvent?.id ? "/command/UpdateNegativeEvent" : "/command/CreateNegativeEvent",
        {
            manual: true,
        }
    );
    const { dictionaryEnumsData, loading: dictionaryEnumsLoading }: any = useDictionaryEnums();
    const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
    const [showSavePopup, setShowSavePopup] = useState<boolean>(false);
    const history = useHistory();
    const hasAccess = commandAccessService.canExecute("CreateNegativeEvent") || commandAccessService.canExecute("UpdateNegativeEvent");
    const userBranchId = tokenStore.getTokenDetails().userBranchId;
    const [branchSystemDate, setBranchSystemDate] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                const currentBranch = await branchService.readCurrentBranch();
                setBranchSystemDate(currentBranch.systemDate);
            } catch (error) {
                console.error("Error fetching branch system date:", error);
            } finally {
                setIsLoading(false);
            }
        };

        initialize();
    }, []);

    if (isLoading || negativeEventLoading || negativeEventListLoading || dictionaryEnumsLoading || !branchSystemDate) {
        return <NxLoader />;
    }

    const activeTypesWithId = new Map(
        negativeEventList
            ?.filter((event: any) => event.status === "ACTIVE")
            ?.map((event: any) => [event.type, event.id])
    );

    const NegativeInformationSchema = Yup.object().shape({
        details: Yup.string().notRequired(),
        type: Yup.string().required()
            .test('type-already-exists', 'This type is already active and cannot be selected', function (type) {
                if (!activeTypesWithId?.has(type)) {
                    // If selected type is not active, it's valid
                    return true;
                }
                // If selected type is active, it's only valid if it belongs to the current event
                return activeTypesWithId?.get(type) === negativeEvent?.id;
            }),
        status: Yup.string().notRequired(),
        dateCreated: Yup.date()
            .typeError('Invalid date format')
            .max(new Date(branchSystemDate), 'Date cannot be in the future')
            .notRequired(),
        dateUpdated: Yup.date()
            .typeError('Invalid date format')
            .max(new Date(branchSystemDate), 'Date cannot be in the future')
            .test('after-date-created', 'Date must be after the negative event date', function (dateUpdated) {
                const dateCreated = this.parent.dateCreated;
                return !dateUpdated || !dateCreated || dateUpdated > dateCreated;
            })
            .notRequired()
    });

    const formInitValues = {
        details: negativeEvent?.details ?? "",
        type: negativeEvent?.type ?? "",
        status: negativeEvent?.status ?? "",
        dateCreated: negativeEvent?.dateCreated ?? branchSystemDate,
        dateUpdated: negativeInformationId
            ? (negativeEvent?.dateUpdated ?? branchSystemDate)
            : ""
    }

    return (
        <>
            {negativeEventLoading || dictionaryEnumsLoading || !branchSystemDate ? (
                <NxLoader />
            ) : (
                <NxFormik<NegativeInformationData>
                    initialValues={formInitValues}
                    validationSchema={NegativeInformationSchema}
                    onSubmit={async (values): Promise<void> => {
                        try {
                            if (values.details === "") {
                                delete values.details
                            }
                            if (values.status === "") {
                                delete values.status
                            }
                            if (values.dateUpdated === "") {
                                delete values.dateUpdated
                            }
                            await createNegativeEvent({
                                method: 'POST',
                                data: {
                                    id: negativeEvent?.id ?? null,
                                    branchId: userBranchId,
                                    customerId: customerId,
                                    ...values
                                }
                            })
                            history.goBack()
                        } catch (err) {
                            console.error(err)
                        }
                    }}
                >
                    {({ submitForm }): ReactElement => {
                        return (
                            <NxForm>
                                <NxStack>
                                   
                                    <NxFormikSelect
                                        disabled={negativeEvent?.reported || !hasAccess}
                                        name="type"
                                        label="Negative event type"
                                        options={Object.entries(dictionaryEnumsData.NegativeEventType).map(([label, value]) => ({
                                            label: label,
                                            value: value,
                                        }))}
                                    />
                                    <NxFormikInput
                                        disabled={!hasAccess}
                                        maxLength={400}
                                        name="details"
                                        label="Negative event details"
                                    />
                                    <NxFormikDatePicker
                                        disabled={!hasAccess}
                                        name="dateCreated"
                                        label="Negative event date"
                                    />
                                    <NxFormikSelect
                                        disabled={!hasAccess}
                                        name="status"
                                        label="Negative event status"
                                        options={Object.entries(dictionaryEnumsData.NegativeEventStatus).map(([label, value]) => ({
                                            label: label,
                                            value: value,
                                        }))}
                                    />
                                    <NxFormikDatePicker
                                        disabled={!hasAccess || !negativeInformationId}
                                        name="dateUpdated"
                                        label="Negative event status update date"
                                    />
                                    <NxRow>
                                        <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(true)}>
                                            Cancel
                                        </NxButton>
                                        {
                                            hasAccess && <NxButton variant={NxButtonVariant.SAVE} onClick={(): void => setShowSavePopup(true)}>
                                                Save
                                            </NxButton>
                                        }
                                    </NxRow>

                                    <NxPopup header="Confirm" open={showSavePopup} description="Save changes?">
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowSavePopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    submitForm();
                                                    setShowSavePopup(false);
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>

                                    <NxPopup
                                        header="Confirm"
                                        open={showCancelPopup}
                                        description="Discard changes and go to previous screen?"
                                    >
                                        <NxRow position={NxRowPosition.END}>
                                            <NxButton variant={NxButtonVariant.CLOSE} onClick={(): void => setShowCancelPopup(false)}>
                                                No
                                            </NxButton>
                                            <NxButton
                                                variant={NxButtonVariant.CONTAINED}
                                                onClick={(): void => {
                                                    setShowCancelPopup(false);
                                                    history.goBack();
                                                }}
                                            >
                                                Yes
                                            </NxButton>
                                        </NxRow>
                                    </NxPopup>
                                </NxStack>
                            </NxForm>
                        );
                    }}
                </NxFormik>
            )}
        </>
    );
};

export default NegativeInformationForm;
